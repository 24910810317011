<template>
  <div class="settings" style=" padding-top: 60px; min-width: 1170px">
    <div class="pro-landing-header">
      <div style="margin-top: 101px;">
        <p class="header-title">
          Bionluk Pro'yu Keşfet
        </p>
        <p class="header-description">
          Suspendisse eget justo orci. Vestibulum ante ipsum primis in faucibus orci luctus et
        </p>
        <div class="header-button">Hemen Keşfet</div>
      </div>
      <div class="header-bottom-div">
        <div class="title-body">
          <div :class="{'title-main': true ,active: activeCategory === category.id}"
               v-for="category in categoryList"
               @click="makeActive(category)">
            <p class="title">{{category.name}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="pro-top-body">
      <div class="pro-card-array">
        <div class="pro-card-body" v-for="card in cardsArray">
          <img class="card-image" :src="card.imageUrl"/>
          <router-link class="card-user-info" :to="card.username">
            <img class="card-avatar" :src="card.avatarUrl"/>
            <div class="card-user-info-inner">
              <p class="card-user-info-username">{{card.username}}</p>
              <p class="card-user-info-title">{{card.title}}</p>
            </div>
          </router-link>
          <img class="pro-card-icon" src="https://gcdn.bionluk.com/site/icon/proBadge_new_3x.png"/>
          <div class="card-user-text"><p class="card-user-text-inner">{{card.text}}</p></div>
        </div>

      </div>
      <img class="pro-icon-big" src="https://gcdn.bionluk.com/site/general/proBadge.png"/>
    </div>

    <div class="pro-center-body">
      <div class="pro-center-infos">
        <img src="https://gcdn.bionluk.com/site/general/pro_i_1.png"/>
        <p class="pro-center-title">Top Quality</p>
        <p class="pro-center-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis placerat aliquam nibh et fermentum. In vel risus
          dignissim, aliquam quam non, pulvinar massa. Nulla suscipit, risus sed pulvinar dictum, neque erat convallis eros, vitae volutpat diam justo sit amet
          nibh. Proin pharetra ipsum vel vestibulum porttitor.</p>
      </div>
      <div class="pro-center-infos">
        <img src="https://gcdn.bionluk.com/site/general/pro_i_2.png"/>
        <p class="pro-center-title">Top Quality</p>
        <p class="pro-center-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis placerat aliquam nibh et fermentum. In vel risus
          dignissim, aliquam quam non, pulvinar massa. Nulla suscipit, risus sed pulvinar dictum, neque erat convallis eros, vitae volutpat diam justo sit amet
          nibh. Proin pharetra ipsum vel vestibulum porttitor.</p>
      </div>
      <div class="pro-center-infos" style="margin-right: 0">
        <img src="https://gcdn.bionluk.com/site/general/pro_i_3.png"/>
        <p class="pro-center-title">Top Quality</p>
        <p class="pro-center-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis placerat aliquam nibh et fermentum. In vel risus
          dignissim, aliquam quam non, pulvinar massa. Nulla suscipit, risus sed pulvinar dictum, neque erat convallis eros, vitae volutpat diam justo sit amet
          nibh. Proin pharetra ipsum vel vestibulum porttitor.</p>
      </div>
    </div>

    <div class="pro-bottom-body">
      <div class="slider-body">
        <carousel :navigationEnabled="false" :perPage="1" class="slider-main">
          <slide v-for="(slider, index) in sliderData" :key="index" class="slider-inner">
            <p class="slider-user-text">"{{slider.text}}"</p>
            <img class="slider-user-image" :src="slider.imageUrl"/>
            <p class="slider-user-username">{{slider.username}}</p>
            <p class="slider-user-title">{{slider.title}}</p>
          </slide>
        </carousel>
      </div>
    </div>

    <div class="pro-footer-body">
      <p>Sen de satıcı olmak ister misin?</p>
      <div>Hemen Başla</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-main-proLanding-proLanding",
    data() {
      return {
        cardsArray: [
          {
            avatarUrl: 'https://i.bionluk.com/images/avatar/200x200/cutecatrULgD37.jpg',
            username: 'michelledoe',
            text: 'Etiam aliquam purus a augue pulvinar, vitae suscipit ipsum pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia ',
            title: 'Tasarimci',
            imageUrl: 'https://i.bionluk.com/images/portfolio/526x296/7802ae40-956a-4282-b57f-365d63f1388b.png'
          },
          {
            avatarUrl: 'https://i.bionluk.com/images/avatar/200x200/cutecatrULgD37.jpg',
            username: 'michelledoe',
            text: 'Etiam aliquam purus a augue pulvinar, vitae suscipit ipsum pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia ',
            title: 'Tasarimci',
            imageUrl: 'https://i.bionluk.com/images/portfolio/526x296/7802ae40-956a-4282-b57f-365d63f1388b.png'
          },
          {
            avatarUrl: 'https://i.bionluk.com/images/avatar/200x200/cutecatrULgD37.jpg',
            username: 'michelledoe',
            text: 'Etiam aliquam purus a augue pulvinar, vitae suscipit ipsum pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia ',
            title: 'Tasarimci',
            imageUrl: 'https://i.bionluk.com/images/portfolio/526x296/7802ae40-956a-4282-b57f-365d63f1388b.png'
          },
          {
            avatarUrl: 'https://i.bionluk.com/images/avatar/200x200/cutecatrULgD37.jpg',
            username: 'michelledoe',
            text: 'Etiam aliquam purus a augue pulvinar, vitae suscipit ipsum pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia ',
            title: 'Tasarimci',
            imageUrl: 'https://i.bionluk.com/images/portfolio/526x296/7802ae40-956a-4282-b57f-365d63f1388b.png'
          },

        ],
        sliderData: [
          {
            imageUrl: 'https://d2fpniz7ghe4fz.cloudfront.net/testi/gunes.jpg?go5',
            text: 'Ucuz olduğu için Bionluk`ta alışveriş yapmıyorduk. Ama ekstra seçeneklerle almak istediğimiz kalitede bir hizmet alabileceğimizi farkettik. Bionluk`ta ihtiyacımız olan işleri kolay ve hızlı bir şekilde yaptırabiliyoruz',
            username: 'Güneş Gülbiter',
            title: 'Sosyal Medya Direktörü, Scorp App'
          },
          {
            imageUrl: 'https://d2fpniz7ghe4fz.cloudfront.net/testi/gunes.jpg?go5',
            text: 'Ucuz olduğu için Bionluk`ta alışveriş yapmıyorduk. Ama ekstra seçeneklerle almak istediğimiz kalitede bir hizmet alabileceğimizi farkettik. Bionluk`ta ihtiyacımız olan işleri kolay ve hızlı bir şekilde yaptırabiliyoruz',
            username: 'Güneş Gülbiter',
            title: 'Sosyal Medya Direktörü, Scorp App'
          }
        ],
        activeCategory: 1,
        categoryList: [
          {
            name: 'Grafik Tasarim',
            id: 1
          },
          {
            name: "İnternet Reklamcılığı",
            id: 2
          },
          {
            name: 'Yazi Ceviri',
            id: 3
          },
          {
            name: "Video Animasyon",
            id: 4
          },
          {
            name: 'Ses Muzik',
            id: 5
          }
        ]
      }
    },

    methods: {
      makeActive(activeCategory) {
        this.activeCategory = activeCategory.id;
        // istek cikilacak burda
      },
      reverseFaq(faq) {
        faq.open = !faq.open;
      },

      openCardModal(card) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.FOR_FREELANCERS, info: card});

      }
    },

    watch: {},

    created() {

    }

  }
</script>

<style scoped lang="scss">

  .pro-landing-header {
    margin: 0 auto;
    height: 506px;
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header-title {
      font-size: 52px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
    }
    .header-description {
      width: 670px;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.33;
      margin: auto;
      margin-top: 20px;
      color: #8b95a1;
    }

    .header-button {
      width: 193px;
      height: 50px;
      border-radius: 2px;
      background-color: #fd4056;
      margin: 0 auto;
      margin-top: 32px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }

  .header-bottom-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-body {
    display: flex;
    justify-content: center;
    padding-top: 17px;
  }

  .title-main {
    cursor: pointer;
    padding-right: 26px;
    padding-left: 26px;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8b95a1;
    .title {
      text-align: center;
    }
  }

  .active {
    margin-bottom: 0;
    border-bottom: 3px solid #fd4056;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fd4056;
  }

  /*513+716+330=1559*/
  /*top*/
  .pro-top-body {
    min-width: 1170px;
    height: 350px;
    padding-bottom: 117px;
    padding-top: 46px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .pro-card-array {
      display: flex;
      justify-content: center;
    }

  }

  .pro-icon-big {
    position: absolute;
    width: 210px;
    height: 250px;
    bottom: -165px;

  }

  .pro-card-body {
    margin-right: 27px;
    -webkit-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    &:last-child {
      margin-right: 0;
    }

    width: 265px;
    height: 350px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    position: relative;
    .card-image {
      width: 265px;
      height: 149px;
    }

    .pro-card-icon {
      position: absolute;
      top: 130px;
      right: 15px;
      width: 28px;
      height: 40px;
    }
  }

  .card-user-info {
    padding: 17px 15px 0 20px;
    display: flex;
    align-items: center;
    .card-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    .card-user-info-inner {
      margin-left: 11px;
      .card-user-info-username {
        font-size: 14px;
        font-weight: 600;
        color: #4b4f52;
      }

      .card-user-info-title {
        margin-top: 1px;
        font-size: 12px;
        text-align: left;
        color: #8b95a1;
      }
    }
  }

  .card-user-text {
    padding: 20px 15px 18px 20px;
    .card-user-text-inner {
      width: 230px;
      height: 111px;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.38;
      text-align: left;
      color: #4b4f52;
    }
  }

  /*center*/
  .pro-center-body {
    min-width: 1170px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .pro-center-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 191px;
    margin-bottom: 278px;
    margin-right: 80px;
    img {
      width: 89px;
      height: 89px;
    }

    .pro-center-title {
      margin-top: 33px;
      font-size: 30px;
      font-weight: 600;
      color: #2d3640;
    }
    .pro-center-description {
      width: 270px;
      margin-top: 14px;
      font-size: 16px;
      line-height: 1.5;
      color: #8b95a1;
      text-align: center;
    }
  }

  /*bottom */
  .pro-bottom-body {
    min-width: 1170px;
    height: 330px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .slider-body {
    position: absolute;
    width: 945px;
    height: 385px;
    background-color: #ffffff;
    border: solid 1px #f4f5f7;
    padding-top: 60px;
    bottom: 85px;
    -webkit-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    .slider-main {
      width: 810px;
      margin: 0 auto;
    }
  }

  .slider-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 810px;
    .slider-user-image {
      margin-top: 28px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .slider-user-text {
      width: 610px;
      max-height: 136px;
      overflow: hidden;
      font-size: 26px;
      font-style: italic;
      line-height: 1.31;
      text-align: center;
      color: #2d3640;
    }

    .slider-user-username {
      margin-top: 17px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #2d3640;
    }

    .slider-user-title {
      margin-top: 7px;
      font-size: 12px;
      text-align: left;
      color: #8b95a1;
    }

  }

  /*footer */

  .pro-footer-body {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 1170px;
    height: 201px;
    background-size: contain;
    background-image: url(https://gcdn.bionluk.com/site/general/pro_red_bg.png);
    p {
      font-size: 24px;
      font-weight: 600;
      text-align: right;
      color: #ffffff;
    }

    div {
      margin-left: 57px;
      width: 200px;
      height: 50px;
      border-radius: 2px;
      background-color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
      line-height: 50px;
    }
  }
</style>
